@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=DM+Serif+Display:ital@0;1&display=swap');

$brand-primary: #deff0a;

$enable-responsive-font-sizes: true;

$light: #f4f4f4;
$dark: #000;
$darker: #0d0d0d;
$red: #b00035;
$green: #4faf00;
$purple: #800080;
$dark-blue: #0C1431;
$body-color: $light;
$accent-color: #c8d3d5;
$accent-light: #f4f4f4;

$link-color: $body-color;
$link-hover-color: $brand-primary;

$font-family-heading: "DM Serif Display", serif;
$font-family-base: "DM Sans", sans-serif;
$font-size-base: 1.25rem;
$h1-font-size: $font-size-base * 3.2;
$h2-font-size: 3rem;
$h3-font-size: 2rem;

$nav-link-padding-x: 0.625rem;
$navbar-brand-font-size: 0.875rem;
$navbar-light-active-color: $body-color;

@import "~bootstrap/scss/bootstrap.scss";
