@import "theme";

html,
body,
#root {
  height: 100%;
}

html {
  background-color: $dark;
  background: repeat center center $dark url("bg.png");
  background-size: 180%;
}

body {
  padding: 0;
  margin: 0;
  letter-spacing: 0.5px;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  color: $accent-color;
  font-weight: 300;
  background-color: transparent;
  text-shadow: 0 0 20px $dark-blue;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.logo {
  width: 68px;
  height: auto;
  border-radius: 50%;

  @include media-breakpoint-down(sm) {
    margin: 0 0 1rem;
    display: block;
  }
}

.contact-links {
  display: flex;
  gap: 1rem;
}

.contact-link {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.875rem;
  font-weight: 700;
  transition: all 0.15s;

  path {
    fill: $body-color;
    transition: all 0.15s;
  }

  &:hover,
  &:focus {
    path {
      fill: $brand-primary;
    }
  }
}

h1,
h2,
h3,
p {
  font-optical-sizing: auto;
}

h1,
h2,
h3 {
  font-family: $font-family-heading;
  font-weight: 400;
}

h2,
h3 {
  color: $brand-primary;
}

h1 {
  line-height: 1.1;
  color: $light;

  b {
    padding-right: 0.5rem;
    font-weight: 400;
    color: $brand-primary;
  }

  @include media-breakpoint-down(xl) {
    font-size: $font-size-base * 2.5;
  }

  @include media-breakpoint-down(sm) {
    font-size: $font-size-base * 1.5;
  }
}

h2 {
  margin: 0;
  line-height: 1.2;
  color: $light;
}

h3 {
  margin: 1.5rem 0 0;
}

p {
  margin: 0;
  margin-top: 1.25rem;
  font-size: $font-size-base;
  line-height: 1.5;

  @include media-breakpoint-down(xl) {
    font-size: 1rem;
  }
}

ul {
  margin: 0;
  padding: 0;
  letter-spacing: normal;
  list-style: none;

  li {
    font-size: calc($font-size-base / 1.5);
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    color: $light;
  }

  &.focus-areas-listing {
    margin-top: 5px;

    li {
      margin-top: 10px;
      margin-right: 20px;
      display: inline-block;
    }

    @include media-breakpoint-up(lg) {
      column-width: 148px;
    }
  }

  &.companies-brands-listing {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 5px;
    column-gap: 15px;
  }

  &.awards-listing {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;

    li {
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 10px;
      flex-basis: 205px;
    }

    img {
      width: 32px;
      height: auto;
    }
  }
}

a,
a:hover {
  text-decoration: none;
}

.container-fluid {
  flex: 1 0 auto;
}

.featured-skills-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 1;

  h2 {
    margin: 0;
  }

  p {
    display: none;
    margin: 1rem 0 0;
    font-size: calc($font-size-base / 1.5);
    font-weight: 700;
  }

  > div {
    padding: 0 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: 50%;
    text-align: center;
    border: 1px solid $accent-light;
    border-left: none;
    border-bottom: none;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 300px;
    }

    &:hover {
      color: $dark;
      background-color: $brand-primary;
      text-shadow: none;

      * {
        color: $dark;
      }

      p {
        display: block;
      }
    }

    @include media-breakpoint-down(xxl) {
      padding: 0 2rem;
    }

    @include media-breakpoint-down(xl) {
      padding: 0 15%;
      flex-basis: 100%;
      border-radius: 0;

      > div {
        height: 200px;
      }
    }

    @include media-breakpoint-down(lg) {
      &:last-child {
        border-bottom: 1px solid $accent-light;
      }
    }

    @include media-breakpoint-up(lg) {
      &:nth-child(1) {
        border-top-right-radius: 10px;
      }
    }

    @include media-breakpoint-up(xl) {
      &:nth-child(1) {
        border-top-right-radius: 0;
      }

      &:nth-child(2) {
        border-top-right-radius: 10px;
      }
    }
  }
}
